import React, { useContext } from "react";
import { UserContext } from "../utils/context";
import { Card, Popconfirm, message, Tag } from "antd";
import { EditOutlined, DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import firebase from "../utils/firebase";

const { Meta } = Card;

const EventCard = ({ data, deleteEvent, inscriptions }) => {
  const db = firebase.firestore();
  const user = useContext(UserContext);
  const history = useHistory();

  const confirmDelete = async (e) => {
    e.stopPropagation();
    try {
      await db.collection("Ateliers").doc(data.id).delete();
      deleteEvent(data.id);
    } catch (error) {
      console.log(error);
      message.error(
        "Une erreur est surevenue lors de la suppression de l'atelier"
      );
    }
  };
  const onEditClick = (e) => {
    e.stopPropagation();
    history.push("/edition_atelier?id=" + data.id);
  };
  const onEventCardClick = (e) => {
    e.stopPropagation();
    history.push("/atelier_details?id=" + data.id);
  };
  let imgErroredOnce = false;
  const onImageError = (e) => {
    if (!imgErroredOnce) {
      e.target.src = process.env.PUBLIC_URL + "/img/@local_photo@2.png";
    }
    imgErroredOnce = true;
  };
  return (
    <Card
      className="event_card"
      onClick={onEventCardClick}
      hoverable
      cover={
        <img
          onError={onImageError}
          className="cover_image"
          alt="cover atelier"
          src={
            data.photoURL.indexOf("@local_photo@") === -1
              ? data.photoURL
              : process.env.PUBLIC_URL + "/img/" + data.photoURL
          }
        />
      }
      actions={
        user && user.email === "admin@synergie.com"
          ? [
              <Popconfirm
                title="Supprimer cet atelier ?"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onConfirm={confirmDelete}
                onCancel={(e) => {
                  e.stopPropagation();
                }}
                okText="Oui"
                cancelText="Non"
              >
                <DeleteOutlined key="delete" />
              </Popconfirm>,
              <EditOutlined key="edit" onClick={onEditClick} />,
            ]
          : []
      }
    >
      {/* <div className="date_chip">
        {new Date(data.date).toLocaleDateString("fr")}
      </div> */}
      <Meta
        title={data.titre}
        description={new Date(data.date).toLocaleDateString("fr")}
      />

      {data.sessions.map((s) => {
        if (
          inscriptions.filter(
            (ins) => ins.id.indexOf("@heure:" + s + "@") !== -1
          ).length === data.limitInscrits
        ) {
          return (
            <Tag key={s} className="session_nb_inscrits" color="red">
              Session {s}h complete
            </Tag>
          );
        }
        return (
          <Tag
            key={s}
            icon={<UserOutlined />}
            className="session_nb_inscrits"
            color="blue"
          >
            <strong>{s}h</strong>
            {" : "}
            {
              inscriptions.filter(
                (ins) => ins.id.indexOf("@heure:" + s + "@") !== -1
              ).length
            }
            /{data.limitInscrits}
          </Tag>
        );
      })}
    </Card>
  );
};
export default EventCard;
