import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../utils/context";
// import { useDummyData, dummyEventData } from "../utils/dummydata";
import firebase from "../utils/firebase";
import EventCard from "./EventCard";

import { AppstoreAddOutlined } from "@ant-design/icons";
import { Tooltip, Card, Skeleton, Divider, message, Alert } from "antd";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "../styles/agenda.css";

const Agenda = () => {
  const user = useContext(UserContext);
  const db = firebase.firestore();
  const history = useHistory();
  const [loadingEventsData, setLoadingEventsData] = useState(true);
  const [eventsData, setEventsData] = useState([]);
  const [allInscriptions, setAllInscriptions] = useState([]);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [errorMsg, setErrorMsg] = useState(null);
  useEffect(() => {
    let isMounted = true;
    let fetchEventsData = async () => {
      setLoadingEventsData(true);
      if (!navigator.onLine) {
        setErrorMsg("Aucune connexion internet");
        setLoadingEventsData(false);
      } else {
        try {
          let fetchedEventsDataSnapshot = await db.collection("Ateliers").get();
          let fetchedInscriptionsSnapshot = await db
            .collection("Inscriptions")
            .get();
          let tempAllInscriptions = [];
          fetchedInscriptionsSnapshot.forEach((doc) => {
            tempAllInscriptions.push({ ...doc.data(), id: doc.id });
          });
          if (isMounted) {
            let tempEventsData = [];
            fetchedEventsDataSnapshot.forEach(function (doc) {
              tempEventsData.push({ ...doc.data(), id: doc.id });
            });
            setErrorMsg(null);
            tempEventsData = tempEventsData.sort((a, b) => a.date - b.date);
            setEventsData(tempEventsData);
            setAllInscriptions(tempAllInscriptions);
            setLoadingEventsData(false);
          }
        } catch (error) {
          console.log(error);
          if (isMounted) {
            message.error(
              "Une erreur est survenue lors du chargement des ateliers"
            );
            if (!navigator.onLine) {
              setErrorMsg("Aucune connexion internet");
            }
            setLoadingEventsData(false);
          }
        }
      }
    };
    fetchEventsData();
    return () => {
      isMounted = false;
    };
  }, [db, isOnline]);
  const setOnline = () => {
    setIsOnline(true);
  };
  const setOffline = () => {
    setIsOnline(false);
  };
  useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);
  const deleteEvent = (eventId) => {
    setEventsData((prv) => prv.filter((ev) => ev.id !== eventId));
  };
  return (
    <div className="agenda_container">
      {" "}
      <div className="event_cards_container">
        {errorMsg ? (
          <Alert message={errorMsg} type="error" />
        ) : (
          <>
            {loadingEventsData ? (
              <>
                <Card className="event_card">
                  <Skeleton.Image active></Skeleton.Image>
                  <Divider />
                  <Skeleton active title={false} />
                </Card>
                <Card className="event_card">
                  <Skeleton.Image active></Skeleton.Image>
                  <Divider />
                  <Skeleton active title={false} />
                </Card>
                <Card className="event_card">
                  <Skeleton.Image active></Skeleton.Image>
                  <Divider />
                  <Skeleton active title={false} />
                </Card>
                <Card className="event_card">
                  <Skeleton.Image active></Skeleton.Image>
                  <Divider />
                  <Skeleton active title={false} />
                </Card>
                <Card className="event_card">
                  <Skeleton.Image active></Skeleton.Image>
                  <Divider />
                  <Skeleton active title={false} />
                </Card>
                <Card className="event_card">
                  <Skeleton.Image active></Skeleton.Image>
                  <Divider />
                  <Skeleton active title={false} />
                </Card>
                <Card className="event_card">
                  <Skeleton.Image active></Skeleton.Image>
                  <Divider />
                  <Skeleton active title={false} />
                </Card>
                <Card className="event_card">
                  <Skeleton.Image active></Skeleton.Image>
                  <Divider />
                  <Skeleton active title={false} />
                </Card>
              </>
            ) : eventsData && eventsData.length > 0 ? (
              eventsData.map((data) => (
                <EventCard
                  deleteEvent={() => {
                    deleteEvent(data.id);
                  }}
                  key={data.id}
                  data={data}
                  inscriptions={allInscriptions.filter(
                    (ins) => ins.id.indexOf(data.id) !== -1
                  )}
                />
              ))
            ) : (
              <Alert
                message={
                  user && user.email === "admin@synergie.com"
                    ? "Aucun atelier trouvé. Vous pouvez en ajouter à l'aide du button ci-contre"
                    : "Aucun atelier trouvé"
                }
                type="warning"
              />
            )}
            {/* {dummyEventData
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .map((data) => (
            <EventCard key={data.id} data={data} />
          ))} */}
            {user && user.email === "admin@synergie.com" && (
              <Tooltip color={"#1890ff"} title="Ajouter un atelier">
                <div
                  className="add_event_btn"
                  onClick={() => {
                    history.push("/edition_atelier?id=" + uuidv4());
                  }}
                >
                  <AppstoreAddOutlined />
                </div>
              </Tooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Agenda;
