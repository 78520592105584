import React, { useEffect, useState } from "react";
import firebase from "./utils/firebase";
import { UserContext } from "./utils/context";
import Navbar from "./Components/Navbar/NavBar";
import Accueil from "./Components/Accueil";
import Infos from "./Components/Infos";
import AboutSynergie from "./Components/AboutSynergie";
import Agenda from "./Components/Agenda";
import EditEvent from "./Components/EditEvent";
import EventDetails from "./Components/EventDetails";
import Inscription from "./Components/Inscription";
import LoginForm from "./Components/LoginForm";
import Footer from "./Components/Footer";
import { BrowserRouter as HashRouter, Switch, Route } from "react-router-dom";
import "./App.css";

export default function App() {
  const auth = firebase.auth();
  auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
  const [user, setUser] = useState(null);
  useEffect(() => {
    let isSubscribed = true;

    const unsubscribe = auth.onAuthStateChanged(async (newUser) => {
      if (newUser) {
        if (isSubscribed) {
          try {
            setUser(newUser);
          } catch (error) {
            console.log(error);
            setUser(null);
          }
        }
      } else {
        setUser(null);
      }
    });
    return () => {
      isSubscribed = false;
      unsubscribe();
    };
  }, [auth]);
  return (
    <UserContext.Provider value={user}>
      <HashRouter>
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Accueil />
          </Route>
          <Route path="/accueil">
            <Accueil />
          </Route>
          <Route path="/synergiefamily">
            <AboutSynergie />
          </Route>
          <Route path="/infos">
            <Infos />
          </Route>
          <Route path="/agenda">
            <Agenda />
          </Route>
          <Route path="/edition_atelier">
            <EditEvent />
          </Route>
          <Route path="/atelier_details">
            <EventDetails />
          </Route>
          <Route path="/inscription">
            <Inscription />
          </Route>
          <Route path="/login">
            <LoginForm />
          </Route>
        </Switch>
        <Footer />
      </HashRouter>
    </UserContext.Provider>
  );
}
