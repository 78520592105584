import React from "react";
import AboutCard from "./AboutCard";
import RubberBand from "react-reveal/RubberBand";
import Fade from "react-reveal/Fade";
import "../styles/aboutsynergie.css";

const AboutSynergie = () => {
  return (
    <div className="about_synergie_container">
      <RubberBand>
        {" "}
        <img
          className="synergie_logo"
          src={process.env.PUBLIC_URL + "/img/logo_synergie_family.png"}
          alt="synergie family logo"
        />
      </RubberBand>

      <Fade left distance={"50px"}>
        <div className="about_subtitle">Qui sommes-nous ?</div>
      </Fade>
      <Fade right distance={"50px"}>
        {" "}
        <div className="about_text">
          Synergie Family est une association qui s’implique avec force et
          conviction depuis plus de 10 ans dans le développement d’actions
          d’innovation éducative et inclusive. La Mission de Synergie Family est
          de constamment innover, créer, tester, modéliser de nouvelles
          initiatives et dispositifs en mettant l’individu au cœur de ses
          propres solutions. Notre champ d’action est aussi large que les
          capacités de nos publics : l’animation, la gestion, l’art,
          l’éducation, l’insertion professionnelle, l’entrepreneuriat, le sport,
          l’inclusion, le handicap, le digital, l’ingénierie sociale.
        </div>
      </Fade>
      <Fade left distance={"50px"}>
        <div className="about_subtitle">La pédagogie de Synergie :</div>
      </Fade>
      <Fade right distance={"50px"}>
        <div className="about_text">
          Pour Synergie Family, chaque individu a un talent, un rêve, une
          qualité, une envie sur laquelle doit se baser son projet
          d’épanouissement. L’action de Synergie Family s’appuie sur des
          concepts clés de réalisation, d’estime de soi et de lien social.
          Aussi, les ateliers que nous vous proposons en complément sont axées
          sur ces compétences, dans lesquelles nous croyons fortement.s
        </div>
      </Fade>
      <Fade left distance={"50px"}>
        <div className="about_subtitle levier_subtitle">Nos 5 leviers :</div>
      </Fade>
      <div className="about_cards">
        <AboutCard
          number={1}
          iconName="confidence"
          text={
            <span>
              Redonner à chacun la <b>CONFIANCE</b> et l’estime de soi
              nécessaire à la construction d’un projet de vie
            </span>
          }
        />
        <AboutCard
          number={2}
          iconName="promotion"
          text={
            <span>
              Permettre à chacun de développer une <b>COMMUNICATION</b>{" "}
              impactante
            </span>
          }
        />
        <AboutCard
          number={3}
          iconName="competence"
          text={
            <span>
              Développer les <b>COMPÉTENCES</b> essentielles du XXIème siècle
            </span>
          }
        />
        <AboutCard
          number={4}
          iconName="settings"
          text={
            <span>
              Rendre accessible la <b>TECHNOLOGIE</b> à tous
            </span>
          }
        />
        <AboutCard
          number={5}
          iconName="teacher"
          text={
            <span>
              Permettre la <b>COMPRÉHENSION</b> des enjeux du monde
              professionnel
            </span>
          }
        />
      </div>
      <Fade bottom distance={"50px"}>
        <div className="about_subtitle centered">
          Les ateliers de Synergie Family se veulent innovants, créatifs et
          collaboratifs.
        </div>
        <div className="about_text centered">
          Notre vocation ? Révéler les talents de chacun.
        </div>
      </Fade>
    </div>
  );
};

export default AboutSynergie;
