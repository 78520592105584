import React, { useContext } from "react";
import { UserContext } from "../../utils/context";
import { Menu, Grid, Popconfirm } from "antd";
import { Link, useLocation, useHistory } from "react-router-dom";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import firebase from "../../utils/firebase";
const { useBreakpoint } = Grid;

const RightMenu = ({ onClose }) => {
  const user = useContext(UserContext);
  const history = useHistory();
  const { md } = useBreakpoint();
  // const screens = useBreakpoint();
  const location = useLocation();
  const locationKey = location.pathname.substring(1);
  const auth = firebase.auth();
  const confirmDeco = async () => {
    await auth.signOut();
    history.push("/login");
  };
  return (
    <Menu
      mode={md ? "horizontal" : "inline"}
      style={{ width: "100vw", maxWidth: "100vw" }}
      // disabledOverflow={true}
      selectedKeys={[
        locationKey === ""
          ? "accueil"
          : locationKey.indexOf("atelier") !== -1
          ? "agenda"
          : locationKey,
      ]}
      overflowedIndicator={<DownOutlined />}
    >
      <Menu.Item key="accueil" className="menu_item_accueil">
        <Link className="menu_item" onClick={onClose} to="/">
          Accueil
        </Link>
      </Menu.Item>
      <Menu.Item key="agenda" className="menu_item_agenda">
        <Link className="menu_item" onClick={onClose} to="/agenda">
          Agenda
        </Link>
      </Menu.Item>
      <Menu.Item key="synergie" className="menu_item_synergie">
        <Link className="menu_item" onClick={onClose} to="/synergiefamily">
          Synergie family
        </Link>
      </Menu.Item>
      <Menu.Item key="infos" className="menu_item_infos">
        <Link className="menu_item" onClick={onClose} to="/infos">
          Infos pratiques
        </Link>
      </Menu.Item>

      {user && localStorage.getItem("last_mail_used") ? (
        <Menu.Item
          key="logout"
          className="menu_item_logout"
          icon={<UserOutlined />}
        >
          <Popconfirm
            title="Se déconnecter ?"
            onConfirm={confirmDeco}
            // onCancel={cancel}
            okText="Oui"
            cancelText="Non"
          >
            {user && user.email
              ? user.email === "admin@synergie.com"
                ? "Admin"
                : localStorage.getItem("last_mail_used") ===
                  "accompagnateur@synergie.com"
                ? "Accompagnateur"
                : localStorage.getItem("last_mail_used")
              : "Invité"}
          </Popconfirm>
        </Menu.Item>
      ) : (
        <Menu.Item key="login" className="menu_item_login">
          <Link className="menu_item" onClick={onClose} to="/login">
            Se connecter
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default RightMenu;
