import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import {
  Form,
  Input,
  Button,
  message,
  Typography,
  InputNumber,
  Alert,
  Radio,
  Divider,
} from "antd";
import DatePicker from "./DatePicker";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import firebase from "../utils/firebase";
import { useLocation } from "react-router-dom";
import "../styles/editevent.css";

const { Title } = Typography;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 28 },
  },
};
const formItemLayoutWithoutBtn = {
  labelCol: {
    xs: { span: 20 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 20, offset: 0 },
    sm: { span: 24, offset: 5 },
  },
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const EditEvent = () => {
  const db = firebase.firestore();
  let query = useQuery();
  let eventId = query.get("id");
  const [form] = Form.useForm();
  const [loadingEventData, setLoadingEventData] = useState(true);
  const [loadingSaveEvent, setLoadingSaveEvent] = useState(false);
  // const [eventData, setEventData] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [photoType, setPhotoType] = useState("presaved");
  const hasDuplicates = (array) => {
    return new Set(array).size !== array.length;
  };
  const onFinish = async (values) => {
    try {
      setLoadingSaveEvent(true);
      values.date = new Date(values.date).getTime();
      console.log("Received values of form:", values);
      if (!values.contenuBis) {
        values.contenuBis = []
      }
      values.sessions.forEach((sessionHour, index) => {
        if (!sessionHour) {
          values.sessions[index] = 9;
        }
      });
      if (hasDuplicates(values.sessions)) {
        throw new Error("doublon_sessions");
      }
      if (values) {
        await db
          .collection("Ateliers")
          .doc(eventId)
          .set(values, { merge: true });
      }
      message.success({
        content: "Atelier enregistré avec succès",
        // className: 'custom-class',
        style: {
          marginTop: "60px",
        },
      });
      setLoadingSaveEvent(false);
    } catch (error) {
      console.log(error.message);
      if (error.message === "doublon_sessions") {
        message.error("Vous ne pouvez pas avoir deux sessions à la même heure");
      } else {
        message.error(
          "Une erreur est surevenue lors de la sauvegarde de l'atelier"
        );
      }

      setLoadingSaveEvent(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    let fetchEventData = async () => {
      setLoadingEventData(true);
      try {
        let fetchedEventData = await db
          .collection("Ateliers")
          .doc(eventId)
          .get();
        if (isMounted) {
          if (fetchedEventData.exists) {
            fetchedEventData = {
              id: fetchedEventData.id,
              ...fetchedEventData.data(),
            };
            // console.log(fetchedEventData);
            // fetchedEventData.date = new Date(fetchedEventData.date);
            if (fetchedEventData.photoURL.indexOf("@local_photo@") !== -1) {
              setPhotoType("presaved");
            } else {
              setPhotoType("url");
            }
            form.setFieldsValue(fetchedEventData);
            // setEventData(fetchedEventData);
          }
          setErrorMsg(null);
          setLoadingEventData(false);
        }
      } catch (error) {
        console.log(error);
        let erMsg = "Une erreur est survenue lors du chargement de l'atelier";
        if (!navigator.onLine) {
          erMsg = "Aucune conexion internet";
        }
        if (isMounted) {
          setErrorMsg(erMsg);
          setLoadingEventData(false);
        }
      }
    };
    fetchEventData();
    return () => {
      isMounted = false;
    };
  }, [db, isOnline, form, eventId]);
  const setOnline = () => {
    setIsOnline(true);
  };
  useEffect(() => {
    window.addEventListener("online", setOnline);
    // window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      // window.removeEventListener('offline', setOffline);
    };
  }, []);
  const changePhotoType = (e) => {
    setPhotoType(e.target.value);
  };


  return (
    <div className="add_new_event_container">
      {!loadingEventData && (
        <Title className="edit_event_en_tete" level={4}>
          Edition d'atelier
        </Title>
      )}
      {loadingEventData ? (
        <Spin />
      ) : errorMsg ? (
        <Alert message={errorMsg} type="error" />
      ) : (
        <Form
          form={form}
          name="dynamic_form_item"
          {...formItemLayoutWithOutLabel}
          onFinish={onFinish}
          className="edit_event_form"
          scrollToFirstError={true}
        >
          <Form.Item
            {...formItemLayoutWithoutBtn}
            name="titre"
            label="Titre"
            rules={[{ required: true, message: "un titre est requis" }]}
          >
            <Input />
          </Form.Item>
          <Radio.Group
            className="photo_type_choice"
            value={photoType}
            onChange={changePhotoType}
          >
            <Radio.Button value="presaved">Photo préenregistrée</Radio.Button>
            <Radio.Button value="url">Photo par lien</Radio.Button>
          </Radio.Group>
          {photoType === "presaved" ? (
            <Form.Item
              {...formItemLayoutWithoutBtn}
              name="photoURL"
              label="Photo"
              rules={[{ required: true, message: "une photo est requise" }]}
            >
              <Radio.Group
                // defaultValue="@local_photo@1.png"
                buttonStyle="solid"
                size="large"
                className="image_picker"
              >
                <Radio.Button value="@local_photo@1.png">
                  {" "}
                  <img
                    className="cover_image"
                    alt="example"
                    // style={{ height: "100%" }}
                    src={process.env.PUBLIC_URL + "/img/@local_photo@1.png"}
                  />
                </Radio.Button>
                <Radio.Button value="@local_photo@2.png">
                  {" "}
                  <img
                    className="cover_image"
                    alt="example"
                    // style={{ height: "100%" }}
                    src={process.env.PUBLIC_URL + "/img/@local_photo@2.png"}
                  />
                </Radio.Button>
                <Radio.Button value="@local_photo@3.png">
                  {" "}
                  <img
                    className="cover_image"
                    alt="example"
                    // style={{ height: "100%" }}
                    src={process.env.PUBLIC_URL + "/img/@local_photo@3.png"}
                  />
                </Radio.Button>
                <Radio.Button value="@local_photo@4.png">
                  {" "}
                  <img
                    className="cover_image"
                    alt="example"
                    // style={{ height: "100%" }}
                    src={process.env.PUBLIC_URL + "/img/@local_photo@4.png"}
                  />
                </Radio.Button>
                <Radio.Button value="@local_photo@5.png">
                  {" "}
                  <img
                    className="cover_image"
                    alt="example"
                    // style={{ height: "100%" }}
                    src={process.env.PUBLIC_URL + "/img/@local_photo@5.png"}
                  />
                </Radio.Button>
                <Radio.Button value="@local_photo@6.png">
                  {" "}
                  <img
                    className="cover_image"
                    alt="example"
                    // style={{ height: "100%" }}
                    src={process.env.PUBLIC_URL + "/img/@local_photo@6.png"}
                  />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          ) : (
            <Form.Item
              {...formItemLayoutWithoutBtn}
              name="photoURL"
              label="Photo"
              rules={[{ required: true, message: "une photo est requise" }]}
            >
              <Input />
            </Form.Item>
          )}

          <Form.Item
            {...formItemLayoutWithoutBtn}
            name="date"
            label="Date"
            rules={[{ required: true, message: "une date est requise" }]}
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
          <Divider />
          {/* START of objectifs */}
          <Form.List
            name="objectifs"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(new Error("Au moins un objectif"));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Objectifs" : ""}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Veuillez remplir ce champ ou supprimez le.",
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="Objectif" style={{ width: "85%" }} />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}

                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => add()}
                    style={{ width: "85%" }}
                    icon={<PlusOutlined />}
                  >
                    Ajouter un objectif
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          {/* end of objectifs */}
          {/* START of contenu */}
          <Form.List
            name="contenu"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(new Error("Au moins un contenu"));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Contenu" : ""}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Veuillez remplir ce champ ou supprimez le.",
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="Contenu" style={{ width: "85%" }} />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => add()}
                    style={{ width: "85%" }}
                    icon={<PlusOutlined />}
                  >
                    Ajouter un contenu
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          {/* end of contenu */}
          {/* START of contenuBis */}
          <Form.List
            name="contenuBis"
            // rules={[
            //   {
            //     validator: async (_, names) => {
            //       if (!names || names.length < 1) {
            //         return Promise.reject(
            //           new Error("Au moins une heure de commencement de session")
            //         );
            //       }
            //     },
            //   },
            // ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Contenu BIS" : ""}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Veuillez remplir ce champ ou supprimez le.",
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder="Élement de la liste de contenu bis"
                        style={{ width: "85%" }}
                      />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => add()}
                    style={{ width: "85%" }}
                    icon={<PlusOutlined />}
                  >
                    Ajouter un contenu BIS
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          {/* end of contenuBis */}
          {/* START of session hours */}
          <Form.List
            name="sessions"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(
                      new Error("Au moins une heure de session")
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Sessions" : ""}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      // rules={[
                      //   {
                      //     required: true,
                      //     whitespace: true,
                      //     message: "Veuillez remplir ce champ ou supprimez le.",
                      //   },
                      // ]}
                      noStyle
                    >
                      <InputNumber
                        // defaultValue={9}
                        min={1}
                        formatter={(value) =>
                          `${value} h`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => add()}
                    style={{ width: "85%" }}
                    icon={<PlusOutlined />}
                  >
                    Ajouter une session
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          {/* end of sessions hours */}
          {/* START of infos pratiques */}
          <Form.List
            name="infosPratiques"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(
                      new Error("Au moins une info pratique")
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Infos pratiques" : ""}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Veuillez remplir ce champ ou supprimez le.",
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder="Info pratique"
                        style={{ width: "85%" }}
                      />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => add()}
                    style={{ width: "85%" }}
                    icon={<PlusOutlined />}
                  >
                    Ajouter une info pratique
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          {/* end of infos pratiques */}
          <Divider />
          <Form.Item
            {...formItemLayoutWithoutBtn}
            name="mailContact"
            label="Mail de contact"
            rules={[
              { required: true, message: "un mail de contact est requis" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            {...formItemLayoutWithoutBtn}
            name="telContact"
            label="Tel de contact"
            rules={[
              { required: true, message: "un telephone de contact est requis" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            {...formItemLayoutWithoutBtn}
            name="limitInscrits"
            label="Limite d'inscrits"
            rules={[
              {
                required: true,
                message: "Une limite du nombre d'inscrits est requise",
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              size="large"
              shape="round"
              htmlType="submit"
              loading={loadingSaveEvent}
            >
              Enregistrer
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EditEvent;
