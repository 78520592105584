import React from "react";
import { Link } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import "../styles/accueil.css";

const Accueil = () => {
  return (
    <div className="accueil_container">
      <Fade top distance={"50px"}>
        <h1 className="accueil_text">
          Dans le cadre de l’Accélérateur de l’emploi mis en place par le
          Département des Bouches-du-Rhône, Synergie Family propose les jeudis
          des ateliers libres ouverts à tous les bénéficiaires du RSA.
          <br />
          Ces ateliers ludiques et innovants permettront à chaque participant de
          développer ses compétences transverses et son adaptabilité face aux
          recruteurs.
          <br />
          Vous trouverez ici l’agenda des différents ateliers, leur descriptif
          ainsi que le lien d’inscription.
          <br />
          Ce site s’adresse aux professionnels de l’Insertion qui peuvent
          directement positionner les personnes qu’ils accompagnent.
        </h1>
      </Fade>
      <Zoom>
        <Link className="cta_btn" to="/agenda">
          Venez découvrir nos différents ateliers !
        </Link>
      </Zoom>
    </div>
  );
};

export default Accueil;
