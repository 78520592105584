import React, { useState, useEffect, useRef } from "react";
import RightMenu from "./RightMenu";
import { Drawer, Button } from "antd";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [visible, setVisible] = useState(false);
  const [menuOverlapLogo, setMenuOverlapLogo] = useState(false);
  const isMounted = useRef(false);
  const resizeTimeout = useRef(null);
  const handleResize = () => {
    clearTimeout(resizeTimeout.current);
    resizeTimeout.current = setTimeout(() => {
      let menuSize = 0;
      let menuElems = document
        .querySelector(".ant-menu")
        .querySelectorAll("li");
      if (menuElems) {
        menuElems.forEach((el) => {
          menuSize += el.offsetWidth;
        });
      }
      if (
        menuSize &&
        menuSize > window.innerWidth - 100 &&
        window.innerWidth >= 768
      ) {
        // console.log(1);
        if (isMounted.current) {
          setMenuOverlapLogo(true);
        }
      } else {
        // console.log(2);
        if (isMounted.current) {
          setMenuOverlapLogo(false);
        }
      }
    }, 500);
  };
  useEffect(() => {
    isMounted.current = true;
    handleResize();
    window.addEventListener("resize", handleResize, false);
    return () => {
      isMounted.current = false;
      clearTimeout(resizeTimeout.current);
      window.removeEventListener("resize", handleResize);
    };
  }, [isMounted,resizeTimeout]);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <nav className="menuBar">
      <div className={"logo " + (menuOverlapLogo ? "hide" : "")}>
        <Link to="/">
          <img
            className="logo_img"
            alt="logo"
            src={
              process.env.PUBLIC_URL +
              "/img/Accelerateur-emploi-provence-bandeau.png"
            }
          />
        </Link>
      </div>
      <div className="menuCon">
        <div className="rightMenu">
          <RightMenu />
        </div>
        <Button className="barsMenu" type="primary" onClick={showDrawer}>
          <span className="barsBtn"></span>
        </Button>
        <Drawer
          // title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <RightMenu onClose={onClose} />
        </Drawer>
      </div>
    </nav>
  );
};

export default Navbar;
