import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

var firebaseConfig = {
    apiKey: "AIzaSyDghRuDLjMy82jNkmO6X1DOCjKAhrtT0uE",
    authDomain: "ateliers-rsa-8a9eb.firebaseapp.com",
    projectId: "ateliers-rsa-8a9eb",
    storageBucket: "ateliers-rsa-8a9eb.appspot.com",
    messagingSenderId: "405620444145",
    appId: "1:405620444145:web:725c91156b78a75c131250",
    measurementId: "G-B5J33J0V7K"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
