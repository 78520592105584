import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../utils/context";
import { Layout } from "antd";
import { Spin, Alert, Divider, List, Space, Button } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import firebase from "../utils/firebase";

import {
  CheckCircleOutlined,
  RightCircleOutlined,
  InfoCircleOutlined,
  CaretRightOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import "../styles/eventdetails.css";
const { Header, Sider, Content } = Layout;
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const EventDetails = () => {
  const user = useContext(UserContext);
  const history = useHistory();
  let query = useQuery();
  let eventId = query.get("id");
  const db = firebase.firestore();
  const [loadingEventData, setLoadingEventData] = useState(true);
  const [eventData, setEventData] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [errorMsg, setErrorMsg] = useState(null);
  useEffect(() => {
    let isMounted = true;
    let fetchEventData = async () => {
      setLoadingEventData(true);
      try {
        let fetchedEventData = await db
          .collection("Ateliers")
          .doc(eventId)
          .get();
        if (isMounted) {
          if (fetchedEventData.exists) {
            fetchedEventData = {
              id: fetchedEventData.id,
              ...fetchedEventData.data(),
            };
            fetchedEventData.date = new Date(fetchedEventData.date);
            setEventData(fetchedEventData);
            setErrorMsg(null);
          } else {
            setErrorMsg("Aucune donnée trouvée");
          }

          setLoadingEventData(false);
        }
      } catch (error) {
        console.log(error);
        let erMsg = "Une erreur est survenue lors du chargement de l'atelier";
        if (!navigator.onLine) {
          erMsg = "Aucune conexion internet";
        }
        if (isMounted) {
          setErrorMsg(erMsg);
          setLoadingEventData(false);
        }
      }
    };
    fetchEventData();
    return () => {
      isMounted = false;
    };
  }, [db, isOnline, eventId]);
  // const onSessionClick = (heure, id) => {
  //   history.push("/inscription?id=" + id + "&session_heure=" + heure);
  // };
  const setOnline = () => {
    setIsOnline(true);
  };
  useEffect(() => {
    window.addEventListener("online", setOnline);
    // window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      // window.removeEventListener('offline', setOffline);
    };
  }, []);
  // let imgErroredOnce = false;
  // const onImageError = (e) => {
  //   if (!imgErroredOnce) {
  //     e.target.src = process.env.PUBLIC_URL + "/img/@local_photo@2.png";
  //   }
  //   imgErroredOnce = true;
  // };
  return (
    <div className="event_details_container">
      {loadingEventData ? (
        <Spin />
      ) : errorMsg ? (
        <Alert message={errorMsg} type="error" />
      ) : eventData ? (
        <Layout className={"event_details_layout"}>
          <Sider>
            <div className="details_titre">{eventData.titre}</div>
            <List
              itemLayout="horizontal"
              dataSource={eventData.objectifs}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<CheckCircleOutlined />}
                    // title={<div>{item}</div>}
                    description={item}
                  />
                </List.Item>
              )}
            />
            <Divider />
            {user && (
              <>
                {" "}
                <Space size="middle" className="sessions_btns_container ">
                  {eventData.sessions.map((session) => (
                    <Button
                      key={session}
                      type="primary"
                      onClick={() => {
                        history.push(
                          "/inscription?id=" +
                            eventData.id +
                            "&session_heure=" +
                            session
                        );
                      }}
                    >
                      Inscription session {session}h
                    </Button>
                  ))}
                </Space>
                <Divider />
              </>
            )}

            <div className="contenu_titre">INFOS PRATIQUES</div>
            <List
              itemLayout="horizontal"
              dataSource={eventData.infosPratiques}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<CaretRightOutlined />}
                    // title={<div>{item}</div>}
                    description={item}
                  />
                </List.Item>
              )}
            />
            <Divider />
            <div className="detail_contact_row">
              <div className="detail_icon_container">
                <MailOutlined style={{ fontSize: 25, color: "#1890ff" }} />
              </div>
              <div className="detail_contact_text">
                <a
                  className="contact_text"
                  href={"mailto:" + eventData.mailContact}
                >
                  {eventData.mailContact}
                </a>
              </div>
            </div>
            <div className="detail_contact_row">
              <div className="detail_icon_container">
                <PhoneOutlined style={{ fontSize: 25, color: "#1890ff" }} />
              </div>
              <div className="detail_contact_text">
                <a
                  className="contact_text"
                  href={"tel:" + eventData.telContact}
                >
                  {eventData.telContact}
                </a>
              </div>
            </div>
          </Sider>
          <Layout>
            <Header>
              <img
                className="event_details_cover_image"
                alt="atelier cover"
                src={
                  eventData.photoURL.indexOf("@local_photo@") === -1
                    ? eventData.photoURL
                    : process.env.PUBLIC_URL + "/img/" + eventData.photoURL
                }
              />
            </Header>
            <Content>
              <div className="contenu_titre">CONTENU</div>
              <List
                itemLayout="horizontal"
                dataSource={eventData.contenu}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<RightCircleOutlined />}
                      // title={<div>{item}</div>}
                      description={item}
                    />
                  </List.Item>
                )}
              />
              {eventData.contenuBis && eventData.contenuBis.length > 0 && (
                <List
                  itemLayout="horizontal"
                  dataSource={eventData.contenuBis}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<InfoCircleOutlined />}
                        // title={<div>{item}</div>}
                        description={item}
                      />
                    </List.Item>
                  )}
                />
              )}
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Alert message={"Aucune donnée trouvée"} type="error" />
      )}
    </div>
  );
};

export default EventDetails;
