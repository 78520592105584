import React from "react";
import Fade from "react-reveal/Fade";

const AboutCard = ({ number, iconName, text }) => {
  return (
    <Fade bottom >
      <div className="about_card">
        <div className="number_container">
          <div className="number">{number}</div>
        </div>
        <img
          src={process.env.PUBLIC_URL + "/img/" + iconName + ".svg"}
          alt="levier icon"
        />
        <div className="levier_text">{text}</div>
      </div>
    </Fade>
  );
};

export default AboutCard;
