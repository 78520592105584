import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import firebase from "../utils/firebase";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "../styles/login.css";

const LoginForm = () => {
  const history = useHistory();
  const auth = firebase.auth();
  auth.useDeviceLanguage();
  const [loaging, setLoading] = useState(false);
  const handleLogin = async (values) => {
    try {
      setLoading(true);
      localStorage.setItem("last_mail_used", values.mail);
      await auth.signInWithEmailAndPassword(
        values.mail.indexOf("admin") !== -1
          ? "admin@synergie.com"
          : "accompagnateur@synergie.com",
        values.password
      );
      history.push("/");
      // setLoading(false);
    } catch (err) {
      console.log(err);
      let frenchErroMsg = null;
      if (err.code === "auth/user-not-found") {
        frenchErroMsg = "Cet utilisateur n'existe pas";
      } else if (err.code === "auth/wrong-password") {
        frenchErroMsg = "Mauvais mot de passe";
      }
      message.error(frenchErroMsg ? frenchErroMsg : err.message);
      setLoading(false);
    }
  };
  return (
    <div className="login_container">
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={handleLogin}
        size={"large"}
      >
        <Form.Item>
          Entrez vôtre adresse mail et le mot de passe d'accompagnateur
        </Form.Item>
        <Form.Item
          name="mail"
          rules={[{ required: true, message: "Un mail valide est requis",type:"email" }]}
        >
          <Input
            size="large"
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Mail"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Le mot de passe est requis" }]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Mot de passe"
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={loaging}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Se connecter
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginForm;
