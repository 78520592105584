import React from "react";
import "../styles/footer.css";

const Footer = () => {
  return (
    <div className="footer_container">
      <div className="footer">
        <div className="footer_img_container">
          <img
            alt="footer_logo"
            src={
              process.env.PUBLIC_URL +
              "/img/Logo Département bouches du rhône.png"
            }
          />
        </div>
        <div className="footer_img_container">
          <img
            alt="footer_logo"
            src={process.env.PUBLIC_URL + "/img/logo-acc.jpg"}
          />
        </div>
        <div className="footer_img_container">
          <img
            className="fse_img"
            alt="footer_logo"
            src={process.env.PUBLIC_URL + "/img/Logo FSE.png"}
          />
        </div>
        <div className="footer_img_container">
          <img
            alt="footer_logo"
            src={process.env.PUBLIC_URL + "/img/Logo Provence Emploi.png"}
          />
        </div>
        <div className="footer_img_container">
          <img
            alt="footer_logo"
            src={process.env.PUBLIC_URL + "/img/Logo Synergie.png"}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
