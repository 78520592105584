import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../utils/context";
import {
  Spin,
  Alert,
  Input,
  Button,
  Table,
  message,
  Divider,
  Popconfirm,
} from "antd";
import { useLocation } from "react-router-dom";
import firebase from "../utils/firebase";
import { CheckSquareOutlined, DeleteOutlined } from "@ant-design/icons";

import "../styles/inscription.css";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Inscription = () => {
  const user = useContext(UserContext);
  let query = useQuery();
  let eventId = query.get("id");
  let sessionHour = query.get("session_heure");
  const db = firebase.firestore();
  const [loadingEventInscriptions, setLoadingEventInscriptions] =
    useState(true);
  // const [eventInscriptions, setEventInscriptions] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [errorMsg, setErrorMsg] = useState(null);
  const [buttonIndexsLoading, setButtonIndexsLoading] = useState([]);
  const [disabledInputIndexs, setDisabledInputIndexs] = useState([]);
  const getEmptyRowData = (index) => {
    return {
      index,
      key: index,
      nom: "",
      prénom: "",
      mail_bénéficiaire: "",
      tel_bénéficiaire: "",
      conseiller: "",
      mail_conseiller: "",
    };
  };

  const [rowsData, setRowsData] = useState([]);
  useEffect(() => {
    let isMounted = true;
    let fetchEventInscriptions = async () => {
      setLoadingEventInscriptions(true);
      try {
        let fetchedEventInscriptionsSnapshot = await db
          .collection("Inscriptions")
          .get();
        let fetchedEventData = await db
          .collection("Ateliers")
          .doc(eventId)
          .get();

        if (isMounted) {
          if (fetchedEventData.exists) {
            fetchedEventData = {
              id: fetchedEventData.id,
              ...fetchedEventData.data(),
            };
            setEventData(fetchedEventData);
            const tempRowsData = [];

            for (let i = 0; i < fetchedEventData.limitInscrits; i++) {
              tempRowsData.push(getEmptyRowData((i + 1).toString()));
            }

            let fetchedEventInscriptions = [];
            fetchedEventInscriptionsSnapshot.forEach((doc, index) => {
              if (doc.id.indexOf(eventId + "@heure:" + sessionHour) !== -1) {
                fetchedEventInscriptions.push(doc.data());
              }
            });
            fetchedEventInscriptions.forEach((fetchEventInscription, index) => {
              tempRowsData[fetchEventInscription.index - 1] = {
                ...tempRowsData[fetchEventInscription.index - 1],
                ...fetchEventInscription,
              };
            });
            let tempDisabledInputIndex = [];
            fetchedEventInscriptions.forEach((inscription) => {
              tempDisabledInputIndex.push(inscription.index);
              setDisabledInputIndexs(tempDisabledInputIndex);
              // hideSomeInputsValues(inscription.index);
            });

            // setEventInscriptions(fetchedEventInscriptions);
            setErrorMsg(null);
            setRowsData(tempRowsData);
          } else {
            setErrorMsg("Aucune donnée cet atelier trouvée");
          }
          setLoadingEventInscriptions(false);
        }
      } catch (error) {
        console.log(error);
        let erMsg = "Une erreur est survenue lors du chargement de l'atelier";
        if (!navigator.onLine) {
          erMsg = "Aucune conexion internet";
        }
        if (isMounted) {
          setErrorMsg(erMsg);
          setLoadingEventInscriptions(false);
        }
      }
    };
    fetchEventInscriptions();
    return () => {
      isMounted = false;
    };
  }, [db, isOnline, eventId, sessionHour]);
  const setOnline = () => {
    setIsOnline(true);
  };
  useEffect(() => {
    window.addEventListener("online", setOnline);
    // window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      // window.removeEventListener('offline', setOffline);
    };
  }, []);
  const replaceWithAsteriscs = (str, start) => {
    if (!start) {
      start = 0;
    }
    return (
      str.substring(0, start) +
      str
        .substring(start, str.length)
        .split("")
        .map((s) => "*")
        .join("")
    );
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const sendSmsAndMail = (inscriptionInfos) => {
    inscriptionInfos["titreAtelier"] = eventData.titre;
    inscriptionInfos["date"] = new Date(eventData.date).toLocaleDateString(
      "fr"
    );
    inscriptionInfos["heure"] = sessionHour;

    return new Promise(async (res, rej) => {
      let mailSentToCandidat = false;
      try {
        await fetch(
          "https://recrutgame.synergiefamily.com/api/mailatelierrsa",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              inscriptionInfos,
              to: inscriptionInfos["mail_bénéficiaire"],
              mailType: "candidat",
            }),
          }
        );
        mailSentToCandidat = true;
        await fetch(
          "https://recrutgame.synergiefamily.com/api/mailatelierrsa",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              inscriptionInfos,
              to: inscriptionInfos["mail_conseiller"],
              mailType: "accompagnateur",
            }),
          }
        );
        res();
      } catch (error) {
        console.log(error);
        if (mailSentToCandidat) {
          // si mail envoyé au candidat mais pas a l'accompagnateur, on continue
          res();
        } else {
          rej("mailing_error");
        }
      }
    });
  };
  // const hideSomeInputsValues = (rowIndex) => {
  //   console.log(rowIndex);
  //   let nomInput = document.querySelector(
  //     ".input_" + rowIndex + "[name='nom']"
  //   );
  //   if (nomInput) {
  //     console.log(nomInput);
  //     nomInput.value = replaceWithAsteriscs(nomInput.value, 2);
  //   }
  // };
  const onInscription = async (e) => {
    e.stopPropagation();
    let buttonId = e.target.id;
    if (!buttonId) {
      buttonId = e.target.parentElement.id;
    }
    let buttonIndex = buttonId.split("@")[0];
    setButtonIndexsLoading((prv) => {
      if (!prv.includes(buttonIndex)) {
        return [...prv, buttonIndex];
      }
      return prv;
    });
    let inputs = document.querySelectorAll(".input_" + buttonIndex);
    let values = { index: buttonIndex };
    let errorMsgs = [];
    inputs.forEach((input) => {
      let errored = false;
      if (input.value.length === 0) {
        if (!errorMsgs.includes("Veuillez remplir tous les champs")) {
          errorMsgs.push("Veuillez remplir tous les champs");
        }
        errored = true;
      } else if (input.name.indexOf("mail") !== -1) {
        if (!validateEmail(input.value)) {
          if (!errorMsgs.includes("Veuillez entrer une adresse mail valide")) {
            errorMsgs.push("Veuillez entrer une adresse mail valide");
          }
          errored = true;
        }
      }
      if (errored) {
        input.classList.add("required");
      } else {
        input.classList.remove("required");
      }
      values[input.name] = input.value;
    });
    if (errorMsgs.length === 0) {
      try {
        const inscriptionDejaFaite = await db
          .collection("Inscriptions")
          .doc(eventId + "@heure:" + sessionHour + "@index" + buttonIndex)
          .get();
        if (inscriptionDejaFaite.exists) {
          message.error(
            "Une inscription à déjà étée éffectuée pour sur cette ligne du tableau. Veuillez rafraichir la page"
          );
        } else {
          // console.log(values);
          await sendSmsAndMail(values);
          await db
            .collection("Inscriptions")
            .doc(eventId + "@heure:" + sessionHour + "@index" + buttonIndex)
            .set(values, { merge: true });
          setDisabledInputIndexs((prv) => {
            if (!prv.includes(buttonIndex)) {
              return [...prv, buttonIndex];
            }
            return prv;
          });
          // hideSomeInputsValues(buttonIndex);
          message.success({
            content: "Inscription envoyée",
            // className: 'custom-class',
            style: {
              marginTop: "60px",
            },
          });
        }

        setButtonIndexsLoading((prv) => prv.filter((p) => p !== buttonIndex));
      } catch (error) {
        console.log(error);
        if (error.indexOf("mailing_error") !== -1) {
          message.error({
            content: "Le mail de confirmation n'a pas pu être envoyé",
            // className: 'custom-class',
            style: {
              marginTop: "60px",
            },
          });
        } else if (error.indexOf("sms_error") !== -1) {
          message.error({
            content: "Le mail de confirmation n'a pas pu être envoyé",
            // className: 'custom-class',
            style: {
              marginTop: "60px",
            },
          });
        }
        setButtonIndexsLoading((prv) => prv.filter((p) => p !== buttonIndex));
      }
    } else {
      errorMsgs.forEach((err) => {
        message.error(err);
      });
      setButtonIndexsLoading((prv) => prv.filter((p) => p !== buttonIndex));
    }
  };
  const confirmDeleteInscription = async (buttonIndex) => {
    try {
      await db
        .collection("Inscriptions")
        .doc(eventId + "@heure:" + sessionHour + "@index" + buttonIndex)
        .delete();
      setDisabledInputIndexs((prv) => prv.filter((idx) => idx !== buttonIndex));

      setRowsData((prv) =>
        prv.map((rowData) => {
          if (rowData.index === buttonIndex) {
            return getEmptyRowData(buttonIndex);
          }
          return rowData;
        })
      );
      let inputs = document.querySelectorAll(".input_" + buttonIndex);
      inputs.forEach((input) => {
        input.value = "";
      });
    } catch (error) {
      console.log(error);
      message.error("Une erreur est surevenue, veuillez réessayer plus tard");
    }
  };
  const columns = [
    {
      title: "Nom",
      dataIndex: "nom",
      key: "nom",
      fixed: "left",
      width: "160px",
      render: (text, data) => {
        return (
          <div className="nom_container">
            <span className="candidat_nb">{data.index}</span>
            <Input
              disabled={disabledInputIndexs.includes(data.index)}
              className={"input_" + data.index}
              name="nom"
              defaultValue={
                user && user.email === "admin@synergie.com"
                  ? text
                  : disabledInputIndexs.includes(data.index)
                  ? replaceWithAsteriscs(text, 2)
                  : text
              }
              placeholder="Nom"
            />
          </div>
        );
      },
    },
    {
      title: "Prénom",
      dataIndex: "prénom",
      key: "prénom",
      width: "127px",
      render: (text, data) => (
        <Input
          disabled={disabledInputIndexs.includes(data.index)}
          className={"input_" + data.index}
          name="prénom"
          defaultValue={
            user && user.email === "admin@synergie.com"
              ? text
              : disabledInputIndexs.includes(data.index)
              ? replaceWithAsteriscs(text, 2)
              : text
          }
          placeholder="Prénom"
        />
      ),
    },
    {
      title: "Mail bénéficiaire",
      dataIndex: "mail_bénéficiaire",
      key: "mail_bénéficiaire",
      width: "160px",
      render: (text, data) => (
        <Input
          disabled={disabledInputIndexs.includes(data.index)}
          className={"input_" + data.index}
          name="mail_bénéficiaire"
          defaultValue={
            user && user.email === "admin@synergie.com"
              ? text
              : disabledInputIndexs.includes(data.index)
              ? replaceWithAsteriscs(text, 2)
              : text
          }
          placeholder="Mail bénéficiaire"
        />
      ),
    },
    {
      title: "Tel bénéficiaire",
      dataIndex: "tel_bénéficiaire",
      key: "tel_bénéficiaire",
      width: "160px",
      render: (text, data) => (
        <Input
          disabled={disabledInputIndexs.includes(data.index)}
          className={"input_" + data.index}
          name="tel_bénéficiaire"
          defaultValue={
            user && user.email === "admin@synergie.com"
              ? text
              : disabledInputIndexs.includes(data.index)
              ? replaceWithAsteriscs(text, 2)
              : text
          }
          placeholder="Tel bénéficiaire"
        />
      ),
    },
    {
      title: "Conseiller.e",
      dataIndex: "conseiller",
      key: "conseiller",
      width: "160px",
      render: (text, data) => (
        <Input
          disabled={disabledInputIndexs.includes(data.index)}
          className={"input_" + data.index}
          name="conseiller"
          defaultValue={text}
          placeholder="Conseiller.e"
        />
      ),
    },
    {
      title: "Mail Conseiller.e",
      dataIndex: "mail_conseiller",
      key: "mail_conseiller",
      width: "160px",
      render: (text, data) => (
        <Input
          disabled={disabledInputIndexs.includes(data.index)}
          type="mail"
          className={"input_" + data.index}
          name="mail_conseiller"
          defaultValue={text}
          placeholder="Mail Conseiller.e"
        />
      ),
    },

    {
      title: "",
      key: "action",
      width: "150px",
      render: (text, data) =>
        !disabledInputIndexs.includes(data.index) ? (
          <Button
            loading={buttonIndexsLoading.includes(data.index)}
            onClick={onInscription}
            id={data.index + "@inscription_btn"}
            type="primary"
          >
            Inscrire
          </Button>
        ) : (
          <div className="deja_inscrit_btn_container">
            <CheckSquareOutlined
              style={{ fontSize: 20, color: "#1eff00", marginRight: "10px" }}
            />
            <span>Inscrit(e)</span>
            <Divider type="vertical" />
            <Popconfirm
              title="Supprimer cette inscription ?"
              onClick={(e) => {
                e.stopPropagation();
              }}
              onConfirm={() => {
                confirmDeleteInscription(data.index);
              }}
              onCancel={(e) => {
                e.stopPropagation();
              }}
              okText="Oui"
              cancelText="Non"
            >
              <DeleteOutlined
                className="delete_inscription_btn"
                style={{ fontSize: 20, color: "red" }}
              />
            </Popconfirm>
          </div>
        ),
    },
  ];

  return (
    <div className="inscription_container">
      {eventData && (
        <div className="inscription_header">
          {/* Inscriptions atelier: <br></br> */}
          <span className="atelier_titre">{eventData.titre}</span>
          <br></br>
          <span className="atelier_date">
            {new Date(eventData.date).toLocaleDateString("fr")} à {sessionHour}h
          </span>
        </div>
      )}

      <div className="forms">
        {loadingEventInscriptions ? (
          <Spin />
        ) : errorMsg ? (
          <Alert message={errorMsg} type="error" />
        ) : (
          <Table
            columns={columns}
            dataSource={rowsData}
            pagination={false}
            scroll={{
              x: 500,
              // y: "50vh"
            }}
            bordered
          />
        )}
      </div>
    </div>
  );
};

export default Inscription;
