import React from "react";
import { UserOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import "../styles/infos.css";

const Infos = () => {
  return (
    <div className="infos_container">
      <div className="contact_infos_container">
        <div className="contact_en_tete">
          Pour plus d’informations, veuillez contacter :
        </div>
        <div className="contact_row">
          <div className="icon_container">
            <UserOutlined style={{ fontSize: 25, color: "#016EBB" }} />
          </div>
          <div className="contact_text">
            Roxane Jubert <br></br> Cheffe de projet Synergie Family
          </div>
        </div>
        <div className="contact_row">
          <div className="icon_container">
            <MailOutlined style={{ fontSize: 25, color: "#016EBB" }} />
          </div>

          <a
            className="contact_text"
            href="mailto:Roxane.jubert@synergiefamily.com"
          >
            Roxane.jubert@synergiefamily.com
          </a>
        </div>
        <div className="contact_row">
          <div className="icon_container">
            <PhoneOutlined style={{ fontSize: 25, color: "#016EBB" }} />
          </div>
          <a className="contact_text" href="tel:0617936718">
            06 17 93 67 18
          </a>
        </div>
        <div className="contact_footer">
          Tous les ateliers se déroulent à l’Hôtel du Département, 52, Avenue
          Saint-Just, 13004 à Marseille
        </div>
      </div>
      <div className="map_container">
        <iframe
          title="google map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2902.990223942312!2d5.403260815384976!3d43.31446407913424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9bf9736c4208b%3A0x777b15931d4a4b64!2sH%C3%B4tel%20du%20D%C3%A9partement!5e0!3m2!1sfr!2sfr!4v1629384655155!5m2!1sfr!2sfr"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
      <div className="last_text">
        En raison du contexte sanitaire, toutes les mesures préventives seront
        prises. Le port du masque est obligatoire dans l’ensemble des locaux.
      </div>
    </div>
  );
};

export default Infos;
